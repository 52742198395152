import _keys from "./keys";
import _from from "./from";
var exports = {};
var keys = _keys,
    from = _from;

exports = function values(o) {
  return !o ? [] : keys(o).map(from(o));
};

export default exports;